(function() {
  'use strict';

  var $ = jQuery.noConflict();

  var self, s;
  app.HomeWidget = {

    settings: {
      elThematic: $('.thematic'),
      elTriggerMenu: $('.header__menu, .navbar__menu'),
      elCloseMenu: $('.navigation__close')
    },

    init: function() {
      self = this;
      s = self.settings;

      self.windowResize();

      window.addEventListener('resize', self.windowResize);

      s.elTriggerMenu.on('click', function() {
        $('body').addClass('navigation--opened');
      });

      s.elCloseMenu.on('click', function() {
        $('body').removeClass('navigation--opened');
      });

      if($('.owl-carousel img').length > 1) {
        var carousel = $('.owl-carousel').owlCarousel({
          items: 1,
          center: true,
          autoHeight: true,
          loop: true,
          margin: 10,
          autoplay: true,
          autoplayHoverPause: true,
          smartSpeed: 750,
          lazyLoad: true,
          lazyLoadEager: 1
        });
      }
      else {
        $('.owl-carousel').removeClass('owl-carousel');
        $('.thematic__button').remove();
      }

      $('.thematic__button--left').on('click', function() {
        carousel.trigger('prev.owl.carousel');
      });

      $('.thematic__button--right').on('click', function() {
        carousel.trigger('next.owl.carousel');
      });

      $('.thematics__gallery').on('click', function() {
        self.openSwipe();
      });
    },

    openSwipe: function() {
      var pswpElement = document.querySelectorAll('.pswp')[0];

      var options = {
        index: 0,
        errorMsg: '<div class="pswp__error-msg">L\'image n\'a pas pu se charger.</div>',
        preload: [1,2]
      };

      var gallery = new PhotoSwipe(pswpElement, PhotoSwipeUI_Default, pswpItems, options);

      gallery.init();
    },

    windowResize: app.debounce(function() {
      $('.thematic__right').height($('.thematic__left').outerHeight());
      $('.studio__left').height($('.studio__right').outerHeight());

      $('.page-studio__right--first .page-studio__holder').height($('.page-studio__left--first').outerHeight());

      $('.page-studio__left .page-studio__holder').height($('.page-studio__right:not(.page-studio__right--first)').outerHeight());
    }, 250)
  };
  jQuery(document).ready(function() {
    app.HomeWidget.init();
  });
})();
